<template>
<job-posting-layout>
  <template #topContent>
    <loader v-if="loading === true" :loading="loading" />
    <div v-if="loading === false" class="stepContent">
      <step-count :count="6"/>
      <step-title title="Select required qualifications" />
      <small style="color: red" v-if="showError.state">{{showError.message}}</small>
      <div class=" tw-flex tw-w-full  tw-flex-col-reverse lg:tw-flex-row">
        <div class="lg:tw-mt-12 lg:tw-w-3/4">
      <div class="lg:tw-mt-10">
        <p class="highest-level">Minimum level of education</p>
          <div class="lg:tw-w-full tw-flex tw-flex-wrap">
              <div v-for="education in levelsOfEducation" :id="education"
                   class="tw-mr-6 tw-mt-5" :class="[education === selectedEducation ? 'selected': 'select']"
                   :key="education" @click="handleSelectedEducation(education)">{{education}}</div>
          </div>
      </div>
      <div class="tw-mt-10 lg:tw-w-9/12" v-if="selectedEducation === 'Others'">
        <p class="skills tw-mt-10 lg:tw-mt-14">Others</p>
        <v-text-field
            hide-details
            label="Please specify"
            class="tw-w-80"
            v-model="otherValue"
            solo
            id="specifyOther"></v-text-field>
        </div>
    </div>

      </div>
    </div>
  </template>

  <template #bottomNav>
    <bottom-navbar @click:back="routeBack" @click:next="updateJobById" :previous="true" :loading="buttonLoading"/>
  </template>
</job-posting-layout>
</template>

<script>
import JobPostingLayout from "../layouts/JobPostingLayout";
import BottomNavbar from "../reuseables/BottomNavbar";
import StepCount from "../reuseables/StepCount";
import StepTitle from "../reuseables/StepTitle";
import {getJobById,updateJobById} from "../../../services/api/APIService"
import Loader from "../../UIComponents/reusablesIcon/Loader";
// import SuggestionView from "./SuggestionView";
export default {
  name: "StepSix",
  components: { Loader, StepTitle, StepCount, BottomNavbar, JobPostingLayout},
  data(){
    return{
      levelsOfEducation : ["Not Required","BSc","MSc","PhD","HND","OND","SSCE","JSCE","Diploma","BA","Others"],
      selectedEducation : "",
      otherValue : "",
      selectedLevelOfEducation :  "",
      loading : false,
      showError:{
        state : false,
        message : ""
      },
      buttonLoading : false
    }
  },
  methods:{
    handleSelectedEducation(education){
      this.selectedLevelOfEducation = education
      return this.selectedEducation = education
    },
    routeBack(){
        this.$router.push({name:'Job-stepFive'})
    },
   async getJobById(){
     this.loading= true
     let job = JSON.parse(sessionStorage.getItem('returnedSelectedDetails'))
     let jobData = {}
     jobData.id = job.id
     jobData.site_name = this.$siteName()
      await getJobById(jobData).then(res => {
        if (res.data.qualifications) {
          let exist = this.levelsOfEducation.find(education => education === res.data.qualifications[0])
          if (!exist) {
            this.selectedEducation = "Others"
            this.otherValue = res.data.qualifications[0]
          }
        else {
          this.selectedEducation = res.data.qualifications[0]
          }}
        this.loading = false
      })
          .catch(err =>{
            console.log(err)
            this.loading = false
            this.$displaySnackbar({message:err.response, success:false})
          })

    },
    updateJobById(){
      this.buttonLoading = true
      let job = JSON.parse(sessionStorage.getItem('returnedSelectedDetails'))
      let jobId = job.id
      let jobDetails = {};
      jobDetails.site_name = this.$siteName()
      let qualifications = [];
      if (this.selectedEducation === "Others"){
        this.selectedLevelOfEducation = this.otherValue
      }
      if (this.selectedLevelOfEducation === ""){
        this.selectedLevelOfEducation = this.selectedEducation
      }
       qualifications.push(this.selectedLevelOfEducation)
      jobDetails.qualifications = qualifications
        this.showError.state = false
        this.showError.message = ""
        updateJobById(jobId,jobDetails).then(() => {
          this.buttonLoading = false
            this.$router.push({name:'Job-stepSeven'})

        })
            .catch(err =>{
              this.buttonLoading = false
              console.log(err)
              this.$displaySnackbar({message:err.response.data.detail, success:false})
            })
    },
  },
  created() {
    this.getJobById()
  }
}
</script>

<style scoped lang="scss">
.highest-level{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 14px;
  color: #1E323F;
}
.otherSpecify{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
  border: 1px solid #9CABB5;
  box-sizing: border-box;
  border-radius: 6px;
}

</style>